import React from "react";

const FilingMenuSecondaryData = ({ sec_filing }) => {
    return (
        <div className="mt-2 bg-gray-50 dark:bg-gray-800 rounded border border-gray-200 dark:border-gray-700">
            <div className="grid grid-cols-2 gap-1 p-2 text-sm">
                <div className="space-y-1.5">
                    <div className="text-gray-500 dark:text-gray-400">Filing Type</div>
                    <div className="text-gray-500 dark:text-gray-400">Filing Date</div>
                    <div className="text-gray-500 dark:text-gray-400">Updated</div>
                </div>
                <div className="space-y-1.5 text-right">
                    <div className="font-medium text-gray-900 dark:text-white">{sec_filing.FilingType}</div>
                    <div className="text-gray-700 dark:text-gray-300">
                        {new Date(sec_filing.FilingDate).toLocaleDateString()}
                    </div>
                    <div className="text-gray-700 dark:text-gray-300">
                        {new Date(sec_filing.Updated).toLocaleString([], {
                            dateStyle: "short",
                            timeStyle: "short",
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default FilingMenuSecondaryData;
import React from 'react';
import { Link } from 'react-router-dom';
import LeftArrowIcon from "../left-arrow.svg";

const CompanyButton = ({ company, ticker }) => {
    const displayName = company?.name || '';
    const displayTicker = ticker ? `(${ticker})` : '';

    return (
        <Link
            to={`/company/${ticker}`}
            className="block transition-all duration-200"
        >
            <div className="flex items-center px-3 py-2 bg-white dark:bg-gray-700 rounded border border-gray-200 dark:border-gray-600 
                hover:border-blue-300 dark:hover:border-blue-500 hover:shadow-sm">
                <img src={LeftArrowIcon} alt="Back to Company" className="w-3 h-3 mr-2 opacity-60" />
                <div className="flex-1">
                    <div className="font-medium text-gray-900 dark:text-white">
                        {displayName}
                    </div>
                    <div className="text-sm text-gray-500 dark:text-gray-400">
                        {displayTicker}
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default CompanyButton;
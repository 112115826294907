import React, { useEffect, useState } from 'react';
import NavBarSmall from "../NavBarSmall";
import Footer from "../Footer";
import { API_URL } from "../../config";
import axiosInstance from '../../api';
import { useAuth } from "../../firebase/AuthContext";
import { Link } from 'react-router-dom';
import CompanyTickerBubbleField from "../SimilarCompanies/CompanyTickerBubbleField";
import UserSubscriptionDetails from "../UserSubscriptionDetails/UserSubscriptionDetails";

const Profile = () => {
    const { currentUser } = useAuth();
    const [userData, setUserData] = useState(null);
    const [showSubscription, setShowSubscription] = useState(false);

    useEffect(() => {
        if (currentUser) {
            document.title = "Settings - " +currentUser.email +" | Modeledge";
        }
    }, [currentUser]);

    useEffect(() => {
        if (currentUser) {
            const headers = currentUser
                ? { Authorization: `Bearer ${currentUser.token}` }
                : {};
            axiosInstance.get(`${API_URL}/api/users/profile`, { headers })
                .then(response => {
                    console.log(response.data)
                    setUserData(response.data)
                })
                .catch(error => {
                    console.error('Error fetching user data:', error);
                });
        }
    }, [currentUser]);

    return (
        <div className="bg-gradient-to-br from-gray-100 to-gray-200 dark:from-gray-900 dark:to-gray-800 bg-cover bg-center min-h-screen flex flex-col justify-between">
            <NavBarSmall />
            <div className="md:w-3/5 w-11/12 mx-auto my-10">
                <div className="bg-gradient-to-br from-gray-100 to-gray-200 dark:from-gray-900 dark:to-gray-800 md:px-10 px-6 md:py-8 py-6 rounded-2xl text-gray-800 dark:text-gray-200 md:space-y-6 space-y-4">
                    {userData ? (
                        <div>
                            <div className="flex justify-between items-center mb-6">
                                <h2 className="text-3xl font-bold text-gray-700 dark:text-gray-200">Account Settings</h2>
                                <div className="flex space-x-3">
                                    <Link to="/highlights" className="px-4 py-2 bg-gray-100 hover:bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-700 text-gray-700 dark:text-gray-200 text-sm font-medium rounded flex items-center justify-between group border border-gray-300 dark:border-gray-600">
                                        <span>My Highlights</span>
                                        <span className="ml-2 text-gray-400 group-hover:text-gray-600 dark:group-hover:text-gray-300">→</span>
                                    </Link>
                                    <Link to="/portfolio" className="px-4 py-2 bg-gray-100 hover:bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-700 text-gray-700 dark:text-gray-200 text-sm font-medium rounded flex items-center justify-between group border border-gray-300 dark:border-gray-600">
                                        <span>My Portfolio</span>
                                        <span className="ml-2 text-gray-400 group-hover:text-gray-600 dark:group-hover:text-gray-300">→</span>
                                    </Link>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-6 bg-gray-50/50 dark:bg-gray-800/50 rounded-xl border border-gray-200/50 dark:border-gray-700/50">
                                {userData.email && (
                                    <div className="space-y-2">
                                        <p className="text-sm uppercase tracking-wider font-semibold text-gray-600 dark:text-gray-400">Email</p>
                                        <p className="text-lg font-medium text-gray-900 dark:text-gray-100">{userData.email}</p>
                                    </div>
                                )}
                                <div className="space-y-2">
                                    <p className="text-sm uppercase tracking-wider font-semibold text-gray-600 dark:text-gray-400">Companies in Portfolio</p>
                                    <p className="text-lg font-medium text-gray-900 dark:text-gray-100">{userData.companiesInPortfolio}</p>
                                </div>

                                <div className="space-y-2">
                                    <p className="text-sm uppercase tracking-wider font-semibold text-gray-600 dark:text-gray-400">Member Since</p>
                                    <p className="text-lg font-medium text-gray-900 dark:text-gray-100">{new Date(userData.userSince).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                                </div>
                            </div>
                            {showSubscription ?
                                <UserSubscriptionDetails currentUser={currentUser} />
                                :
                                <button
                                    onClick={() => setShowSubscription(!showSubscription)}
                                    className="px-5 py-2.5 mt-4 bg-gray-700 hover:bg-gray-600 dark:bg-gray-700 dark:hover:bg-gray-600 text-white rounded-lg transition-all duration-200 ease-in-out font-medium"
                                >
                                    Show Subscription Details
                                </button>
                            }

                            <div className="col-span-2 mt-6">
                                <p className="text-sm uppercase tracking-wider font-semibold text-gray-600 dark:text-gray-400 mb-3">Portfolio Companies</p>
                                <div className="flex flex-row flex-wrap p-4 border border-gray-200/50 dark:border-gray-600/50 rounded-xl bg-gray-50/50 dark:bg-gray-800/50 backdrop-blur-sm shadow-inner">
                                    <CompanyTickerBubbleField companies={userData.companies} />
                                </div>
                            </div>

                        </div>
                    ) : (
                        <div className="flex justify-center items-center h-96">
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Profile;
import React, { useState, useEffect } from 'react';
import axiosInstance from '../../api';
import { useNavigate } from 'react-router-dom';
import { API_URL } from "../../config";
import CompanyTickerBubbleField from "./CompanyTickerBubbleField";

const SimilarCompanies = ({ ticker, similar }) => {
    const [companies, setCompanies] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        setCompanies([]);
        setIsLoading(true);

        if (similar) {
            setCompanies(similar)
            setIsLoading(false);
            return
        }

        const fetchData = async () => {
            try {
                const response = await axiosInstance.get(`${API_URL}/api/company/facts/similar/${ticker}`);
                setCompanies(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [similar, ticker]);

    const handleGroupButtonClick = (ticker) => {
        navigate(`/industry/filings/company/${ticker}`);
    };

    if (isLoading) {
        return (
            <>
            </>
            // <div className="flex items-center justify-center h-20">
            //     <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-r-2 border-neutral-700"></div>
            // </div>
        );
    }

    if (!companies || !Array.isArray(companies) || companies.length === 0) {
        return (
            <div className="text-gray-600 italic text-sm"></div>
        );
    }

    return (
        <div className="rounded-lg mt-2">
            <div className="flex flex-wrap items-center gap-0.5">
                <CompanyTickerBubbleField companies={companies} />
                <button
                    className="bg-neutral-600 hover:bg-neutral-700 text-white text-sm font-medium py-1 px-2 rounded transition duration-300 ease-in-out flex items-center"
                    onClick={() => handleGroupButtonClick(ticker)}
                >
                    <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                    </svg>
                    View Group Filings
                </button>
            </div>

        </div>
    );
};

export default SimilarCompanies;
import React, {useState, useEffect, useRef} from "react";
import axiosInstance from '../../api';
import { useNavigate } from 'react-router-dom';
import { API_URL } from "../../config";
import FilingCardCompanySearch from "../FilingCards/FilingCardCompanySearch";
import { DocumentSearchIcon, ViewGridIcon } from '@heroicons/react/outline';
//import {useDarkModeTable} from "../../hooks/useDarkModeTable";


const TableSearchHints = () => (
    <div className="flex justify-center bg-filing-menu-background dark:bg-gray-800">
        <div className="flex-col p-6 rounded-lg bg-gray-50 dark:bg-gray-700">
            <h4 className="dark:text-gray-200">Don't see your table? Try refining your search</h4>
            <ul className="list-disc text-sm text-gray-500 dark:text-gray-400 px-2 py-4">
                <li>"Sales" instead of "revenue"</li>
                <li>"Total" when applicable</li>
            </ul>
            <h4 className="dark:text-gray-200">Try company or industry specific terms:</h4>
            <ul className="list-disc text-sm text-gray-500 dark:text-gray-400 px-2 py-4">
                <li>"ARR", "customers"</li>
                <li>"tons", "reserves"</li>
                <li>"throughput"</li>
            </ul>
        </div>
    </div>
);

const SearchTypeToggle = ({ searchType, setSearchType }) => (
    <div className="flex items-center gap-0.5 bg-gray-100 dark:bg-gray-700 rounded-full p-0.5 shadow-sm">
        <button
            onClick={() => setSearchType('table')}
            className={`p-1.5 rounded-full transition-all duration-200 ${
                searchType === 'table'
                    ? 'bg-white dark:bg-gray-600 text-gray-800 dark:text-gray-200 shadow-sm ring-1 ring-gray-200 dark:ring-gray-500'
                    : 'text-gray-400 dark:text-gray-500 hover:text-gray-600 dark:hover:text-gray-300'
            }`}
            title="Table Search"
        >
            <ViewGridIcon className="h-3.5 w-3.5"/>
        </button>
        <button
            onClick={() => setSearchType('document')}
            className={`p-1.5 rounded-full transition-all duration-200 ${
                searchType === 'document'
                    ? 'bg-white dark:bg-gray-600 text-gray-800 dark:text-gray-200 shadow-sm ring-1 ring-gray-200 dark:ring-gray-500'
                    : 'text-gray-400 dark:text-gray-500 hover:text-gray-600 dark:hover:text-gray-300'
            }`}
            title="Document Search"
        >
            <DocumentSearchIcon className="h-3.5 w-3.5"/>
        </button>
    </div>
);

function TableSearchDivV6({ ticker }) {
    const [searchType, setSearchType] = useState('table');
    const [searchTerm, setSearchTerm] = useState("");
    const [tables, setTables] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(0);
    const scrollContainerRef = useRef(null);

    const handleTableClick = (id) => {
        navigate(`/tables/${ticker}/${id}`);
    };

    const handleTableFilingClick = (filing) => {
        navigate(`/company/${ticker}/filing/${filing.filing_type}/${filing.accession_number}`);
    };

    useEffect(() => {
        const debounceTimeout = setTimeout(() => {
            if (searchTerm && searchType === 'table') {
                setError(null);
                axiosInstance
                    .get(`${API_URL}/api/tables/search/${ticker}?q=${searchTerm}`)
                    .then((response) => {
                        setTables(response.data);
                    })
                    .catch((error) => {
                        console.error("Error fetching suggestions:", error);
                        setError(error);
                    });
            } else {
                setTables([]);
            }
        }, 200);

        return () => clearTimeout(debounceTimeout);
    }, [searchTerm, ticker, searchType]);

    useEffect(() => {
        if (searchTerm === "") {
            setTables([])
        }
    }, [searchTerm]);

    const handleKeyDown = (e) => {
        if (searchType === 'table') {
            if (e.key === "ArrowDown") {
                setSelectedSuggestionIndex((prevIndex) =>
                    Math.min(prevIndex + 1, tables.length - 1)
                );
            } else if (e.key === "ArrowUp") {
                setSelectedSuggestionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
            } else if (e.key === "Enter") {
                if (selectedSuggestionIndex >= 0 && tables[selectedSuggestionIndex]?.id) {
                    const selectedTable = tables[selectedSuggestionIndex];
                    handleTableClick(selectedTable.id);
                }
            }
        } else if (searchType === 'document' && e.key === "Enter") {
            search();
        }
    };

    const clearSearch = () => {
        setSearchTerm("");
    };

    const search = () => {
        if (searchType === 'document') {
            navigate(`/company/search/${ticker}?query=${searchTerm}`);
        }
    };

    if (error) {
        return <div>Error fetching data</div>;
    }

    const getPlaceholderText = () => {
        return searchType === 'table'
            ? "Search for tables: revenues, customers, cash flow, debt, net income..."
            : "Search in documents...";
    };

    return (
        <div className="container mx-auto pt-2 px-1 md:px-0 w-full">
            <div className="flex flex-col justify-start w-full">
                <div className="w-full mx-auto">
                    <div className="flex items-center bg-filing-menu-background dark:bg-gray-800 shadow-sm rounded text-sm border border-gray-200 dark:border-gray-700 py-1.5 px-2 mb-2 space-x-2">
                        <div className="flex items-center gap-3 w-full">
                            <SearchTypeToggle searchType={searchType} setSearchType={setSearchType} />
                            <div className="relative flex flex-1 items-center w-full">
                                {searchType === 'table' && <ViewGridIcon className="absolute left-2 h-4 w-4 text-gray-400 dark:text-gray-500" />}
                                {searchType === 'document' && <DocumentSearchIcon className="absolute left-2 h-4 w-4 text-gray-400 dark:text-gray-500" />}
                                <input
                                    type="text"
                                    placeholder={getPlaceholderText()}
                                    className="flex-grow border-none bg-white dark:bg-gray-700 text-gray-600 dark:text-gray-200 text-sm p-1.5 pl-8 pr-2 outline-none rounded focus:ring-1 focus:ring-gray-300 dark:focus:ring-gray-600 dark:placeholder-gray-400"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                />
                                {searchType === 'table' ? (
                                    <button
                                        className="ml-2 bg-gray-100 dark:bg-gray-600 hover:bg-gray-200 dark:hover:bg-gray-500 text-gray-600 dark:text-gray-200 text-xs font-medium py-1.5 px-3 rounded-full whitespace-nowrap transition-colors"
                                        onClick={clearSearch}
                                    >
                                        Clear
                                    </button>
                                ) : (
                                    <button
                                        className="ml-2 bg-gray-100 dark:bg-gray-600 hover:bg-gray-200 dark:hover:bg-gray-500 text-gray-600 dark:text-gray-200 text-xs font-medium py-1.5 px-3 rounded-full whitespace-nowrap transition-colors"
                                        onClick={search}
                                    >
                                        Search
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>

                    {searchType === 'table' && searchTerm && tables && tables.length > 0 && (
                        <div className="relative w-full overflow-hidden border-neutral-400 dark:border-gray-600 border border-1 rounded-md">
                            <div
                                ref={scrollContainerRef}
                                className="flex overflow-x-auto pb-4 pt-2 space-x-2 scrollbar-hide"
                                style={{
                                    scrollbarWidth: 'none',
                                    msOverflowStyle: 'none',
                                    WebkitOverflowScrolling: 'touch'
                                }}
                            >
                                <div className="flex-shrink-0 bg-filing-menu-background dark:bg-gray-800"/> {/* Spacer */}
                                {tables.map((table, index) => (
                                    <div key={table.id} className="bg-filing-menu-background dark:bg-gray-800 min-w-fit">
                                        <div
                                            className="flex-shrink-0 border border-gray-300 dark:border-gray-600 rounded-md cursor-pointer text-black dark:text-gray-200 bg-gray-50 dark:bg-gray-700 max-w-3xl pb-2"
                                        >
                                            <div
                                                onClick={() => handleTableFilingClick(table.filing)}
                                                className="rounded-md"
                                            >
                                                <FilingCardCompanySearch filing={table.filing} exhibit={table.exhibit}/>
                                            </div>
                                            <div
                                                onClick={() => handleTableClick(table.id)}
                                            >
                                                <div className="flex justify-center md:px-2 break-words bg-white text-black"
                                                     dangerouslySetInnerHTML={{__html: table.html}}/>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="bg-filing-menu-background dark:bg-gray-800 min-w-fit max-w-2xl">
                                    <div
                                        className="flex-shrink-0 border border-gray-300 dark:border-gray-600 rounded px-2 text-black dark:text-gray-200 bg-filing-menu-background dark:bg-gray-800">
                                        <div className="bg-filing-menu-background dark:bg-gray-800 py-4">
                                            Table search tips:
                                        </div>
                                        <div className="bg-filing-menu-background dark:bg-gray-800">
                                            <TableSearchHints/>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-shrink-0 bg-filing-menu-background dark:bg-gray-800"/>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default TableSearchDivV6;
// subscriptionPlans.js
export const subscriptionPlans = {
    basic: {
        name: "Basic Plan",
        price: 35,
        stripePriceId: "price_1POnLyJi3dGFaXl4HRpG8Q0d",
        active: true,
        features: [
            "Comprehensive SEC Filing Access",
            "AI Language Model querying",
            "Table search",
            "Corporate Transcripts",
            "Designed for rapid get-up-to-speed",
            "Be smarter than your friends"
        ],
        description: "Dive deep into the world of financial reporting with our Basic Plan. Access a wealth of SEC filings and corporate transcripts, enhanced with specialized qualitative information to give you the edge in your investment decisions."
    },
    pro: {
        name: "Pro Plan (Coming Soon)",
        price: 199,
        stripePriceId: "price_1POnLyJi3dGFaXl4HRpG8Q0d",
        active: false,
        features: [
            "All Basic Plan Features",
            "AI Language Model querying, without limit",
            "Curated Investment Ideas Repository",
            "Best Single Stock Investment Ideas On Internet",
            "Industry Insights",
            "Be a lot smarter than your friends"
        ],
        description: "Elevate your investment strategy with our Pro Plan. In addition to comprehensive SEC filings and corporate transcripts, gain access to the best investment idea repo on the web. In development."
    }
};
import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const getExhibitIcon = (type) => {
    // Return different icons based on exhibit type
    if (type.startsWith("EX-99")) {
        return (
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
            </svg>
        );
    }
    if (type === "GRAPHIC") {
        return (
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
        );
    }
    // Default document icon
    return (
        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
        </svg>
    );
};
/*
exhibitType === "EX-99.1" ||
          exhibitType === "EX-99" ||
          exhibitType === "EX-99.2" ||
          exhibitType === "EX-99.3" ||
          exhibitType === "EX-99.4" ||
          exhibitType === "EX-99.5" ||
          exhibitType === "EX-99.6" ||
          exhibitType === "EX-99.7" ||
          exhibitType === "EX-99.8" ||
          exhibitType === "EX-99.9" ||
          exhibitType === "EX-99.10" ||
          exhibitType === "EXHIBIT 99.1" ||
          exhibitType === "PRESS RELEASE" ||
          exhibitType === "EX-99.01" ||
          exhibitType === "EX-99.02" ||
          exhibitType === "EX-99.03" ||

 */

const getExhibitTypeColor = (exhibitType) => {
    if ((exhibitType === "EX-99.1") ||
        (exhibitType === "EX-99") ||
        (exhibitType === "EX-99.2") ||
        (exhibitType === "EX-99.3") ||
        exhibitType === "EX-99.4" ||
        exhibitType === "EX-99.5" ||
        exhibitType === "EX-99.6" ||
        exhibitType === "EX-99.7" ||
        exhibitType === "EX-99.8" ||
        exhibitType === "EX-99.9" ||
        exhibitType === "EX-99.10" ||
        exhibitType === "EXHIBIT 99.1" ||
        exhibitType === "PRESS RELEASE" ||
        exhibitType === "EX-99.01" ||
        exhibitType === "EX-99.02" ||
        exhibitType === "EX-99.03" ||
        exhibitType === "EX-99..1" ||
        exhibitType === "EX-99..01") {
        return "blue";
    }

    if (exhibitType.startsWith("EX-10")) {
        return "blue";
    }

    const colors = {
        "GRAPHIC": "invalid",
        "4": "invalid",
        "INFORMATION TABLE": "invalid",
        "8-K": "blue",
        "10-K": "blue",
        "10-Q": "blue",
        "20-F": "blue",
        "DEF 14A": "blue",
        "default": "gray"
    };
    
    const color = Object.keys(colors).find(key => exhibitType.startsWith(key)) || "default";
    return colors[color];
};



const FilingMenuExhibitList = ({ exhibits, ticker, type, sec_filing }) => {
    return (
        <div className="space-y-1">
            {exhibits.map((exhibit) => {
                const colorScheme = getExhibitTypeColor(exhibit.type);
                return (
                    <Link
                        key={exhibit.id}
                        to={`/company/${ticker}/filing/${type}/${sec_filing.AccessionNumber}/exhibit/${exhibit.sequence}`}
                        className="block"
                    >
                        <div className={`bg-white dark:bg-gray-700 rounded border border-gray-200 dark:border-gray-600 
                            hover:border-${colorScheme}-300 dark:hover:border-${colorScheme}-500 
                            hover:shadow-sm transition-all duration-200`}
                        >
                            <div className="py-1.5 px-2">
                                <div className="flex items-center space-x-2">
                                    <span className={`bg-${colorScheme}-100 dark:bg-${colorScheme}-900 
                                        text-${colorScheme}-800 dark:text-${colorScheme}-200 
                                        px-2 py-0.5 rounded text-xs font-medium flex items-center`}
                                    >
                                        <span className="mr-1">{getExhibitIcon(exhibit.type)}</span>
                                        {exhibit.type}
                                    </span>
                                    {exhibit.type !== "GRAPHIC" && (
                                        <span className="text-xs text-gray-600 dark:text-gray-300 truncate">
                                            {exhibit.description || "No description available"}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Link>
                );
            })}
        </div>
    );
};

FilingMenuExhibitList.propTypes = {
    exhibits: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            type: PropTypes.string.isRequired,
            description: PropTypes.string,
            sequence: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        })
    ).isRequired,
    ticker: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    sec_filing: PropTypes.shape({
        AccessionNumber: PropTypes.string.isRequired,
    }).isRequired,
};

export default FilingMenuExhibitList;
// Auth.js
import React, { useEffect, useState } from 'react';
import { useAuth } from '../firebase/AuthContext';
import { useNavigate } from 'react-router-dom';
import EmailPassword from './EmailPassword';

function Auth({ redirectPath = '/portfolio' }) {

    const { signInWithGoogle, signInWithTwitter, currentUser, error } = useAuth();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (currentUser) {
            navigate(redirectPath);
        }
    }, [currentUser, navigate, redirectPath]);

    const handleGoogleLogin = async () => {
        try {
            await signInWithGoogle();
        } catch (err) {
            console.error("Error during Google login:", err);
        }
    };

    const handleTwitterLogin = async () => {
        try {
            await signInWithTwitter();
        } catch (error) {
            console.error("Error during Twitter login:", error);
        }
    };


    const handleEmailLogin = () => {
        navigate('email')
    };

    return (
        <div className="h-screen text-white p-4 md:p-8 lg:p-8 flex items-center justify-center bg-opacity-0 ">

            <div className="bg-[#141313] rounded-lg overflow-hidden shadow-2xl p-16 md:p-16 w-full sm:w-2/3 md:w-1/2 lg:w-1/3 border-white border-opacity-20 border backdrop-blur-sm transition-all duration-300 hover:shadow-3xl">
                <h2 className="md:text-3xl lg:text-3xl sm:text-2xl font-bold mb-2 md:mb-3 lg:mb-3 text-center">Login / Sign up to Modeledge</h2>
                <p className="text-gray-400 text-center text-sm mb-6">Access professional-grade financial analysis tools</p>
                
                <div className="border border-gray-600 rounded-lg mt-2 bg-opacity-50">
                    <div className="px-2 pt-2">
                        <button
                            onClick={handleGoogleLogin}
                            type="button"
                            className="text-white w-full bg-[#333333] hover:bg-[#000000]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-3 text-center inline-flex items-center justify-between hover:translate-y-[-1px] hover:shadow-lg mx-auto mb-2"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 48 48" className="abcRioButtonSvg"><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"/><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"/><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"/><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"/><path fill="none" d="M0 0h48v48H0z"/></svg>

                            Google<div></div>
                        </button>
                    </div>

                    <div className="px-2">
                        <button
                            onClick={handleTwitterLogin}
                            type="button"
                            className="text-white w-full bg-[#333333] hover:bg-[#000000]/90 focus:ring-4 focus:outline-none focus:ring-[#1DA1F2]/50 font-medium rounded-lg text-sm px-5 py-3 text-center inline-flex items-center justify-between hover:translate-y-[-1px] hover:shadow-lg mx-auto"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 512 462.799"><path fillRule="nonzero" d="M403.229 0h78.506L310.219 196.04 512 462.799H354.002L230.261 301.007 88.669 462.799h-78.56l183.455-209.683L0 0h161.999l111.856 147.88L403.229 0zm-27.556 415.805h43.505L138.363 44.527h-46.68l283.99 371.278z"/></svg>
                            Twitter<div></div>
                        </button>
                    </div>
                    <div className="flex items-center justify-center px-2 my-1">
                        <div className="border-t border-gray-600 flex-grow"></div>
                        <span className="px-4 text-gray-400 text-sm">or</span>
                        <div className="border-t border-gray-600 flex-grow"></div>
                    </div>
                    <div className="px-2 mb-2">
                        <button
                            type="button"
                            className="text-white w-full bg-[#333333] hover:bg-[#000000]/90 focus:ring-4 focus:outline-none focus:ring-gray-500/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center justify-between dark:focus:ring-gray-500/55 mx-auto"
                            onClick={handleEmailLogin}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
                            </svg>
                            Login with Email<div></div>
                        </button>
                    </div>

                    {showModal &&
                        <EmailPassword closeModal={() => setShowModal(false)} error={error} />}

                </div>
                <div className="mt-8 text-center">
                    <button
                        type="button"
                        className="text-gray-400 hover:text-white transition-colors duration-200 text-sm"
                        onClick={() => navigate(-1)}
                    >
                        ← Back to previous page
                    </button>
                </div>

            </div>
        </div>
    );
}

export default Auth;
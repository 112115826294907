import React from 'react';

const QueryOptions = ({ setDataSet, isDarkMode }) => {
    const [isFullText, setIsFullText] = React.useState(false);

    const handleToggleChange = () => {
        setIsFullText(!isFullText);
        setDataSet(!isFullText ? 'transcripts_full_text' : 'current_data');
    };

    return (
        <div className="flex flex-col space-y-2">
            <div className="flex items-center justify-between p-4 rounded-lg bg-neutral-200 dark:bg-gray-900/50 border border-gray-200 dark:border-gray-700" onClick={handleToggleChange}>
                <div className="flex-1 mr-4">
                    <div className="text-sm font-medium text-gray-900 dark:text-gray-200">
                        Data Toggle
                    </div>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                        {isFullText
                            ? "Full transcript mode"
                            : "News and filings mode"}
                    </p>
                </div>
                <button
                    onClick={handleToggleChange}
                    className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors ${
                        isFullText ? 'bg-blue-500' : 'bg-yellow-500 dark:bg-yellow-500'
                    }`}
                    role="switch"
                    aria-checked={isFullText}
                >
                    <span className="sr-only">Toggle data mode</span>
                    <span
                        className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
                            isFullText ? 'translate-x-6' : 'translate-x-1'
                        }`}
                    />
                </button>
            </div>

            <div className={`px-4 flex items-start space-x-2`}>
                <span className="text-amber-500 text-xs">⚠️</span>
                <p className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                    Responses are generated by an AI language model which can make mistakes, verify its outputs.
                </p>
            </div>
        </div>
    );
};

export default QueryOptions;
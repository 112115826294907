// Login.js
import NavBarSmall from "./NavBarSmall";
import React from 'react';
import Footer from "./Footer";
import BackgroundWrapper from "./LandingBackground";
import AuthWithEmail from "./AuthWithEmail"
import { useLocation } from 'react-router-dom';

function LoginWithEmail() {
    const location = useLocation();
    const customRedirect = location.state?.redirectPath || '/portfolio';

    return (
        <div className="bg-background-cover-theme bg-cover bg-center min-h-screen flex flex-col justify-between">
            <NavBarSmall />
            <BackgroundWrapper>
                <AuthWithEmail redirectPath={customRedirect} />
            </BackgroundWrapper>
            <Footer/>
        </div>
    )
}
export default LoginWithEmail;

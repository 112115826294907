// Landing.js
import React, {useEffect} from 'react';

import NavBarSmall from "./NavBarSmall";
import Footer from "./Footer";
import LandingBackground from "./LandingProductBackground";
import SubscriptionTier from './SubscriptionTier';
import { subscriptionPlans } from '../subscriptions/subscriptionPlans';


function Landing() {

    useEffect(() => {
        document.title = "Subscribe | Modeledge";
    }, []);


    return (
        <>
            <NavBarSmall />
            <LandingBackground className="flex-grow">
                <div className="container mx-auto px-4 py-4">

                    <div className="mt-8 mb-16 flex flex-col md:flex-row justify-center items-stretch space-y-8 md:space-y-0 md:space-x-8">
                        <SubscriptionTier tier={subscriptionPlans.basic} />
                        <SubscriptionTier tier={subscriptionPlans.pro} />
                    </div>

                </div>
            </LandingBackground>
        <Footer />
        </>
    )
}

export default Landing;
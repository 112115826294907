import React from 'react';
import { Link } from 'react-router-dom';
import RecentFilingsEmptyPortfolio from "./RecentFilingsEmptyPortfolio";
import { themeColors } from "../theme/colors";

const EmptyPortfolio = () => {
    return (
        <div className={`text-center p-4 md:p-6 ${themeColors.background.primary}`}>
            <div className="text-center p-6">
                <div className={`text-2xl font-bold mb-3 ${themeColors.text.primary}`}>
                    Follow companies to see their recent filings
                </div>
                <div className={`text-lg mb-6 ${themeColors.text.secondary}`}>
                    Your portfolio is empty!
                </div>

                <div className="flex flex-wrap justify-center gap-2 mt-2">
                    {[
                        { name: 'NVIDIA', ticker: 'NVDA' },
                        { name: 'Apple', ticker: 'AAPL' },
                        { name: 'Alphabet', ticker: 'GOOGL' },
                        { name: 'Microsoft', ticker: 'MSFT' },
                        { name: 'Amazon', ticker: 'AMZN' },
                        { name: 'Meta', ticker: 'META' },
                        { name: 'Berkshire Hathaway', ticker: 'BRK-B' }
                    ].map((company) => (
                        <Link
                            key={company.ticker}
                            to={`/company/${company.ticker}`}
                            className="transition-all duration-200"
                        >
                            <div className="px-3 py-1.5 bg-[#1a1f2e] hover:bg-[#252b3b] text-gray-300 rounded-sm">
                                {company.name}
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
            <div className="w-full mt-12">
                <div className={`text-2xl font-bold mb-6 ${themeColors.text.primary}`}>Recent Filings</div>
                <div className="w-full">
                    <RecentFilingsEmptyPortfolio portfolioName={"empty"} />
                </div>
            </div>
        </div>
    );
};

export default EmptyPortfolio;
// AuthWithEmail.js
import React, { useEffect } from 'react';
import { useAuth } from '../firebase/AuthContext';
import { useNavigate } from 'react-router-dom';
import EmailPassword from './EmailPassword';

function AuthWithEmail({ redirectPath = '/portfolio' }) {

    const { currentUser, error } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser) {
            navigate(redirectPath);
        }
    }, [currentUser, navigate, redirectPath]);

    return (
        <div className="h-screen text-white p-4 md:p-8 lg:p-8 flex items-center justify-center bg-opacity-0 ">

            <div className="bg-[#141313] rounded-lg overflow-hidden shadow-2xl p-16 md:p-16 w-full sm:w-2/3 md:w-1/2 lg:w-1/3 border-white border-opacity-20 border backdrop-blur-sm transition-all duration-300 hover:shadow-3xl">
                <h2 className="md:text-3xl lg:text-3xl sm:text-2xl font-bold mb-2 md:mb-3 lg:mb-3 text-center">Login / Sign up to Modeledge</h2>
                <p className="text-gray-400 text-center text-sm mb-6">Access professional-grade financial analysis tools</p>


                <div className="border border-gray-600 rounded-lg mt-2 bg-opacity-50">

                    <EmailPassword error={error} />

                </div>
                <div className="mt-8 text-center">
                    <button
                        type="button"
                        className="text-gray-400 hover:text-white transition-colors duration-200 text-sm"
                        onClick={() => navigate(-1)}
                    >
                        ← Back to previous page
                    </button>
                </div>

            </div>
        </div>
    );
}

export default AuthWithEmail;
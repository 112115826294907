import React, { useState, useEffect } from "react";
import axiosInstance from '../api';
import PortfolioColumn from "../Components/PortfolioColumn";
import { API_URL } from "../config";
import Spinner from "./Spinner/Spinner";

function RecentFilingsEmptyPortfolio({ portfolioName }) {
    const [portfolio, setPortfolio] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function fetchEmptyPortfolioFill() {
            setIsLoading(true);
            try {
                const res = await axiosInstance.get(`${API_URL}/api/users/portfolio/${portfolioName}`);
                console.log("API response:", res.data);
                console.log("Length:", res.data.length);
                console.log("isArray:", Array.isArray(res.data));
                setPortfolio(res.data);
            } catch (error) {
                console.error("Error fetching main portfolio:", error);
            } finally {
                setIsLoading(false);
                console.log("finally")
            }
        }
        fetchEmptyPortfolioFill();
    }, [portfolioName]);

    return (
        <div className="flex items-center justify-center min-h-[200px]">
            {isLoading ? (
                <Spinner />
            ) : (
                portfolio && portfolio.filings &&
                <PortfolioColumn
                    filings={portfolio.filings}
                    portfolioName={portfolioName}
                />
            )}
        </div>
    );
}

export default RecentFilingsEmptyPortfolio;